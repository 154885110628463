//
// Place variable overrides first, then import just the styles you need. Note that some stylesheets are required no matter what.

// Toggle global options

@import "variables";
@import "custom";
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/variables-dark.scss";

@import "../../../node_modules/bootstrap/scss/maps";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/utilities";
@import "../../../node_modules/bootstrap/scss/root";
@import "../../../node_modules/bootstrap/scss/reboot";

@import "../../../node_modules/bootstrap/scss/type";
@import "../../../node_modules/bootstrap/scss/images";
@import "../../../node_modules/bootstrap/scss/containers";
@import "../../../node_modules/bootstrap/scss/grid";
@import "../../../node_modules/bootstrap/scss/tables";
@import "../../../node_modules/bootstrap/scss/forms";
@import "../../../node_modules/bootstrap/scss/buttons";
// @import "../../../node_modules/bootstrap/scss/transitions";
@import "../../../node_modules/bootstrap/scss/dropdown";
@import "../../../node_modules/bootstrap/scss/button-group";
@import "../../../node_modules/bootstrap/scss/nav";
@import "../../../node_modules/bootstrap/scss/navbar"; // Requires nav
// @import "../../../node_modules/bootstrap/scss/card";
@import "../../../node_modules/bootstrap/scss/breadcrumb";
// @import "../../../node_modules/bootstrap/scss/accordion";
@import "../../../node_modules/bootstrap/scss/pagination";
// @import "../../../node_modules/bootstrap/scss/badge";
@import "../../../node_modules/bootstrap/scss/alert";
// @import "../../../node_modules/bootstrap/scss/progress";
// @import "../../../node_modules/bootstrap/scss/list-group";
@import "../../../node_modules/bootstrap/scss/close";
// @import "../../../node_modules/bootstrap/scss/toasts";
@import "../../../node_modules/bootstrap/scss/modal"; // Requires transitions
// @import "../../../node_modules/bootstrap/scss/tooltip";
// @import "../../../node_modules/bootstrap/scss/popover";
// @import "../../../node_modules/bootstrap/scss/carousel";
// @import "../../../node_modules/bootstrap/scss/spinners";
@import "../../../node_modules/bootstrap/scss/offcanvas"; // Requires transitions
// @import "../../../node_modules/bootstrap/scss/placeholders";

// Helpers
// @import "helpers";

// Utilities
@import "../../../node_modules/bootstrap/scss/utilities/api";

//
// Custom styles
//

@import "icon-list";

h2,
h3 {
  font-family: $font-family-sans-serif;
  color: $blue;
  font-weight: bold;
}

h2 {
  font-size: 1.4em;
}
h3 {
  font-size: 1.2em;
}

body {
  margin: 0;
  padding: 0;
}

ol,
ul {
  li {
    margin-bottom: 0.75em;
  }
}

ol li::marker {
  font-weight: bold;
}

header {
  nav.top,
  .search {
    background: $light-grey;
    font-family: $font-alternate;
    font-size: 13px;
  }
  .search {
    border: none;
  }
  nav.top {
    .icon {
      height: 16px;
      width: auto;
    }
    a {
      text-decoration: none;
      color: $body-color;
    }
    ul {
      li {
        margin: 0 1.5em;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  nav.prime {
    min-height: 4.5em;
    .navbar-brand {
      margin-top: -3em;
      position: relative;
      top: 0.75em;
    }
    ul.navbar-nav {
      li {
        margin: 0 1em;
        &.last {
          margin-right: 0;
        }
      }
      a {
        color: $body-color;
      }
    }
    .dropdown-menu {
      left: auto;
      top: 3em;
      .category {
        img {
          float: left;
          width: 3em;
          height: 3em;
          margin: -0.25em 1em 1.25em 0;
        }
        margin-bottom: 1em;
        min-height: 70px;
        .item {
          color: $blue;
          font-weight: bold;
          &:hover {
            color: $green;
          }
        }
        .description {
          font-size: 0.8em;
          color: $dark;
          display: block;
        }
      }
      &.drop-1 {
        right: 22%;
      }
      &.drop-2 {
        right: 15%;
      }
      &.drop-3 {
        right: 8%;
      }
    }
  }
}

#language_select_form {
  select {
    border: none;
    background: none;
  }
}

main {
  .heading {
    &.h-img {
      height: 24em;
    }
    &.product-placement {
      .titles {
        margin: 3em 0 0 1em;
        span {
          font-size: 1.4em;
          font-weight: bold;
        }
      }
      .col-md-8 {
        .down {
          bottom: 0;
          left: 40%;
        }
      }
      .product-img {
        padding: 1em 0;
      }
    }
    .overlay {
      background: rgba(13, 22, 27, 0.8);
      padding: 1em 0;
      position: absolute;
      width: 100%;
      bottom: 0;
      color: #fff;
    }
    position: relative;
    background-size: cover;
    background-position-x: 80%;
    background-position-y: 80%;
    &.no-img {
      height: 14em;
      background-image: url("/static/img/abstract.webp");
      background-position-x: 0;
      background-position-y: 0;
    }
    h1 {
      font-weight: bold;
      font-size: 1.8em;
    }
  }
  .intro {
    background-color: $light-grey;
    background-size: cover;
    background-position-x: 80%;
    background-position-y: 80%;
    display: block;
    height: 50vh;
    min-height: 27em;
    position: relative;
    .overlay {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.8),
        rgba(0, 0, 0, 0)
      );
    }
    h1 {
      color: #fff;
      max-width: 14em;
      padding-top: 100px;
    }
    .btn-group-vertical {
      padding: 3em 0;
    }
    .btn {
      max-width: 16em;
      display: block;
      display: grid;
      grid-template-columns: 3em 1fr;
      img {
        height: 2em;
      }
    }
  }
  .block {
    &.infobox {
      padding-top: 0;
      .box {
        max-width: 40em;
        padding: 0.75em;
        background: $blue;
        border-radius: 0.5em;
        display: grid;
        grid-template-columns: 5em 1fr;
        justify-content: center;
        align-items: center;
      }
      .symbol {
        padding-left: 0.5em;
      }
      .content {
        padding-top: 1em;
        color: $white;
      }
    }
    &.cta-form {
      .container {
        max-width: 45em;
      }
      .title {
        text-align: center;
      }
      .submit {
        text-align: center;
      }
    }
    &.catalog {
      .container {
        max-width: 60em;
        table {
          margin-bottom: 6em;
        }
      }
    }
    &.download-app {
      text-align: center;
    }
    &.image-quote {
      text-align: center;
      img {
        margin-bottom: 1em;
      }
      p {
        font-size: 1.6em;
      }
    }
    &.features {
      img {
        display: block;
        margin: 0 auto;
        padding: 2em;
      }
      h3 {
        text-align: center;
      }
      ul {
        list-style: none;
        li {
          &:before {
            content: "";
            border-color: transparent $green;
            border-style: solid;
            border-width: 0.35em 0 0.35em 0.45em;
            display: block;
            height: 0;
            width: 0;
            left: -1em;
            top: 1.1em;
            position: relative;
          }
        }
      }

      .cta {
        text-align: center;
      }
    }
    &.boxed {
      background-color: $light-grey;
    }
    &.enlarge {
      .text {
        display: table;
        .content {
          p,
          ol,
          ul {
            font-size: 1.4em;
            color: #666;
            li {
              margin-bottom: 1em;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
          display: table-cell;
          vertical-align: middle;
        }
      }
    }
    &.text {
      display: block;

      margin: 0 auto;
      .col {
        max-width: 42em;
      }
    }
    padding: 3em 0;

    .sub {
      text-align: center;
      max-width: 36em;
      margin: 0 auto 1em auto;
    }
    &.columns {
      img {
        margin-bottom: 1em;
      }
      .container {
        .row {
          .col {
            .cta {
              text-align: center;
            }
          }
        }
      }
    }
  }
}

.btn {
  img {
    height: 1em;
    width: auto;
  }
}

footer {
  background-color: $dark;
  padding: 2em 0 1em 0;
  color: $light-grey;
  a {
    color: $light-grey;
    padding: 0.25em;
    text-decoration: none;
    &:hover {
      color: darken($light-grey, 10%);
      text-decoration: underline;
    }
  }
  ul {
    list-style: none;
    padding-left: 0;
    li {
      margin: 1em 0;
      font-weight: bold;
      font-size: 1.4em;
      ul {
        padding-left: 0;
        li {
          font-weight: normal;
          font-size: 0.9em;
        }
      }
    }
  }
  .logo {
    display: block;
    margin: 0 1em 0.5em -0.3em;
  }
  .contact {
    a {
      display: block;
    }
  }
  .disclaimer {
    border-top: lighten($dark, 10%) 1px solid;
    text-align: center;
    margin-top: 1em;
    padding-top: 1em;
    color: darken($light-grey, 20%);
    a {
      color: darken($light-grey, 20%);
    }
    span {
      margin-left: 4em;
    }
  }
  .socialmedia {
    a {
      margin: 0 0.25em;
    }
  }
}
.youtube-video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.youtube-video-container::after {
  display: block;
  content: "";
  padding-top: 56.25%;
}

.youtube-video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

video {
  width: 100%;
  height: auto;
}

.rnd {
  border-radius: 50%;
}

.smartphone {
  position: relative;
  width: 290px;
  height: 640px;
  margin: auto;
  border: 16px #000 solid;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;
  &:before {
    content: "";
    display: block;
    width: 60px;
    height: 5px;
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 10px;
  }
  video {
    display: inline-block;
    vertical-align: baseline;
  }
}
.app-link {
  img {
    width: 150px;
    height: 45px;
    margin: 1em 1em 1em 0;
  }
}
.icon {
  &.down {
    width: 2.5em;
    margin: 4em auto 2em auto;
    display: block;
    img {
      width: 2.5em;
    }
  }
}

@include media-breakpoint-down(md) {
  .dealers {
    display: none;
  }
  #mainNav {
    margin-top: 1em;
    display: none;
    &.show {
      display: block;
    }
  }
}

#breadcrumb {
  font-size: 0.9em;
  background: $light-grey;
  padding: 0.75em 0;
  ol {
    &.breadcrumb {
      margin-bottom: 0;
    }
  }
}

@keyframes slideFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.hidden {
  display: none !important;
}
.cookie_consent button {
  display: block;
  max-width: 16em;
  width: 100%;
  margin: 0.5em 0 0 0;
  padding: 1em 0;
  font-weight: bold;
  background: #045e9a;
  border: none;
  cursor: pointer;
  color: #fff;
}
.cookie_consent button.secondary {
  background: none;
  color: #55823b;
  border: 0.25em solid #55823b;
}
.cookie_consent button.tertiary {
  background: #045e9a;
  color: #fff;
}
.cookie_consent button:hover {
  text-decoration: underline;
}
#consent_banner {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  z-index: 100;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2em;
  flex-wrap: wrap;
  box-shadow: -1px 38px 80px 0 rgba(0, 0, 0, 0.8);
  color: #333;
  background: #fff;
  padding: 0.5em 1em 1em 1em;
  line-height: 1.5em;
  border-top: 2px solid #ccc;
  animation: 0.35s ease-in-out 0s 1 slideFromBottom;
  font-size: 0.8em;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
#consent_banner p {
  margin: 0;
}
#consent_banner a {
  color: #55823b;
  font-weight: bold;
}
#consent_banner .disclaimer {
  align-self: center;
  font-weight: bold;
  font-size: 1.2em;
  margin-top: 0.5em;
}
#consent_banner .cta {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  gap: 1em;
}
#consent_curtain {
  position: fixed;
  overflow-y: auto;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100%;
  z-index: 110;
  background: rgba(0, 0, 0, 0.2);
}
#consent_curtain .ck-modal {
  overflow-y: auto;
  color: #333;
  font-size: 12px;
  line-height: 18px;
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  z-index: 120;
  max-width: 52em;
  margin: 2em auto;
  background: #fff;
  border-radius: 1em;
  padding: 1em;
  animation: 0.2s ease-in-out 0s 1 fadeIn;
}
#consent_curtain .ck-modal .logo img {
  max-width: 100%;
  height: auto;
  margin: 1.5em;
}
#consent_curtain .ck-modal h2 {
  margin-top: 0.5em;
}
#consent_curtain .ck-modal .header,
#consent_curtain .ck-modal .cookies {
  display: grid;
  grid-template-columns: 14em 1fr;
  gap: 1em;
}
#consent_curtain .ck-modal .cookies {
  border-top: 1px solid #ccc;
  padding-top: 1em;
}
#consent_curtain .ck-modal .cookies ul.filters {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
#consent_curtain .ck-modal .cookies ul.filters li button {
  font-size: 12px;
  padding: 0.75em 0.5em;
  border: 1px solid #ccc;
  color: #333;
  background: #fff;
}
#consent_curtain .ck-modal .cookies ul.filters li button.active {
  background: #eee;
  color: #55823b;
}
#consent_curtain .ck-modal .cookies .cookie_content {
  max-height: 15em;
  overflow-y: auto;
}
#consent_curtain .ck-modal .cookies .cookie_content .cookie {
  display: none;
}
#consent_curtain .ck-modal .cookies .cookie_content .cookie.active {
  display: block;
}
#consent_curtain .ck-modal .save {
  min-height: 1.5em;
  padding: 0.75em 0 0.5em 0;
}
#consent_curtain .ck-modal .save button {
  font-size: 12px;
  float: right;
}
#consent_curtain .ck-modal .save button.secondary {
  padding: 0.77em 0;
  margin-right: 1em;
}
#curtain_close {
  float: right;
  cursor: pointer;
  border: none;
  background: url("../img/close.svg");
  width: 1.5em;
  height: 1.5em;
  padding: 0.25em;
}
#cookies_update {
  margin: 0.5em 0 0 auto;
}
@media only screen and (min-width: 900px) {
  #consent_banner {
    grid-template-columns: 1fr 25em;
  }
  #consent_banner .cta {
    justify-content: right;
  }
  #consent_banner .cta button {
    padding: 0.75em 0;
  }
}
@media only screen and (max-width: 540px) {
  #consent_curtain .ck-modal {
    margin: 1em 0 0 0;
    border-radius: 1em 1em 0 0;
    top: unset;
    transform: unset;
    position: absolute;
    bottom: 0;
  }
  #consent_curtain .ck-modal .header {
    grid-template-columns: 1fr;
    margin-top: -1em;
  }
  #consent_curtain .ck-modal .header img.logo {
    display: none;
  }
  #consent_curtain .ck-modal .cookies {
    grid-template-columns: 1fr;
    height: 14em;
  }
  #consent_curtain .ck-modal .cookies ul.filters li {
    display: inline-flex;
  }
  #consent_curtain .ck-modal .save button {
    display: block;
    max-width: unset;
    float: unset;
  }
  #consent_curtain .ck-modal .filters li button {
    font-size: 12px;
  }
  #cookies_update {
    margin: 0.5em auto 0 auto;
  }
}
.cookie_switch {
  float: right;
  margin: -0.5em 10px 0 0;
}
.cookie_switch .cookie_display {
  display: block;
  float: left;
  width: 30px;
  height: 30px;
  margin: 0 5px 0 0;
}
.cookie_switch .cookie_display.enabled {
  background: url("../img/check-green.svg");
}
.cookie_switch .cookie_display.necessary {
  background: url("../img/check-grey.svg");
}
.switch {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 28px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 14px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: #fff;
  border-radius: 50%;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
input:checked + .slider {
  background-color: #2196f3;
}
input:disabled + .slider {
  background-color: #ccc;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.image-overlay {
  position: relative;
  display: inline-block;
}

.image-overlay a {
  position: relative;
  display: block;
}

.image-overlay img {
  display: block;
  width: 100%;
  height: auto;
}

.card-video-overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Adjust the z-index as needed to control the overlay stacking */
  pointer-events: none; /* Allow clicks to pass through the overlay to the underlying image */
  img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Adjust the object-fit property as needed to control how the overlay image fits within the container */
  }
  video {
    width: 100%;
    height: 100%;
    background: #dadada;
  }
  
}

.disclosure {
  font-size: 0.85em;
  color: #999;
}