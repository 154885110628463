$enable-gradients: false;
$enable-shadows: true;



// Customize some defaults
$body-color: #333;
$body-bg: #fff;
$border-radius: 0.4rem;
$success: #7952b3;
$blue: #0d161b;
$green: #69e5c3;
$light-grey: #ebebeb;
$dark: #0C161C;
$purple: #343F6F;

$primary: $blue;
$secondary: $green;

$font-family-sans-serif: "Source Sans Pro", sans-serif;
$font-alternate: "Montserrat", sans-serif;

$navbar-padding-y: 0.25em;
$navbar-nav-link-padding-x: 0.25em;

$nav-link-padding-y: 0.05rem !default;
$nav-link-padding-x: 1rem !default;
$nav-link-font-size: 1.1em;
$nav-link-color: $body-color;

$dropdown-font-size: 1.1em;
$dropdown-spacer: 0.5rem;
$grid-gutter-width: 3rem;

$btn-border-radius: .25em;
$btn-padding-x: 1em;
$btn-padding-y: .75em;