/* Modal Styles */
.modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

.modal video {
    width: 80%;
    max-width: 800px;
    margin: 20px auto;
    display: block;
}

/* Close Button */
.close {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 30px;
    cursor: pointer;
    color: white;
}

.close:hover {
    color: #f44336;
}

/* Button Style */
#openModal {
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    cursor: pointer;
}

#openModal:hover {
    background-color: #0056b3;
}
