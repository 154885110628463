// Example of a custom component

.icon-list {
    padding-left: 0;
    list-style: none;
  }
  .icon-list li {
    display: flex;
    align-items: flex-start;
    margin-bottom: .25rem;
  }
  .icon-list li::before {
    display: block;
    flex-shrink: 0;
    width: 1.5em;
    height: 1.5em;
    margin-right: .5rem;
    content: "";
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23212529' viewBox='0 0 16 16'%3E%3Cpath d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z'/%3E%3C/svg%3E") no-repeat center center / 100% auto;
  }
  